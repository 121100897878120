export default {
  blogerName: 'PUZ',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/puz777',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@puzzanos?si=VtkwemXzjRayqRRR',
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@puzzanosi?si=yPFOdu-qCmiAfscI',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs.com/ca60f2edd',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs.com/cf9b860bf',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c27d596ae',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs.com/c8ca49d11',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs.com/c6d7e1085',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-nxoyfjmrn.com/c036d4df6',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-tmmpgchred.com/c4e0c86a8',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-egikvdtdev.com/ceeb74755',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-mutlukir.com/c01ef7203',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c21819273',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs.com/c8f12f13f',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/c5260b781',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>PUZ</strong> и получи 125 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'PUZ',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>125FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
